[data-aos="fade-up"] {
    min-height: 200px;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-section {
    padding: 50px;
    background-color: #1a1a1a;
    color: #fff;
    text-align: center;
    border-radius: 8px;
}

.contact-section form {
    margin: 20px 0;
}

.contact-section input,
.contact-section textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    font-family: 'Libre Franklin', sans-serif;
    background-color: #282828;
}

.contact-section button {
    background-color: #a8a2ff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-section .contact-info {
    margin-top: 20px;
}