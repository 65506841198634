html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Libre Franklin', sans-serif;
    background: #121212;
    color: #fff;
    text-align: center;
}