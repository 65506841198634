nav {
    background-color: transparent;
    transition: background-color 0.3s;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    min-height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-bg {
    background-color: #1a1a1a;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    font-size: 1.2rem;
    text-decoration: none;
    color: #fff;
}

nav ul li a:hover {
    color: #a8a2ff;
}