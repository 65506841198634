[data-aos="fade-up"] {
    min-height: 200px;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.projects-section {
    justify-content: center;
    padding: 25px;
    background-color: #1a1a1a;
    color: #fff;
    text-align: center;
    border-radius: 8px;
}

.projects-section {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.projects-heading {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.project-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
}

.project-card {
    background-color: #282828;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    white-space: normal;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}

.project-card h3 {
    text-align: center;
    color: #a8a2ff;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-card p {
    text-align: center;
    color: #e0e0e0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.project-card-link {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    width: 100%;
}

.project-card-link:hover .project-card {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}