.footer {
  background-color: #1a1a1a;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.footer-icons {
  display: flex;
  justify-content: center;
}

.footer-icons a {
  margin: 0 10px;
  color: #fff;
  font-size: 24px;
}

.footer-icons a:hover {
  color: #a8a2ff;
}